$(document).ready(function(){
    $('#top-search').click(function(){
        var data = {
            "ad.rooms": {
                "operation": "set",
                "data": checks('top-search-rooms')
            },
            "district.id": {
                "operation": "set",
                "data": select('top-search-districts')
            },
            "house.floor": {
                "operation": "range",
                "data": range('top-search-floors')
            },
            "house.buildYear": {
                "operation": "range",
                "data": range('top-search-build_year')
            },
            "complex.id": {
                "operation": "set",
                "data": select('top-search-complexes')
            },
            "ad.price": {
                "operation": "range",
                "data": range('top-search-price')
            },
            "ad.squareAll": {
                "operation": "range",
                "data": range('top-search-square_all')
            }
        };

        var entity = $(this).attr('data-entity');

        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: '/api/search',
            data: {'entity': entity,'data': data}
        }).complete(function(data){
            document.location.href = data.responseJSON.url;
        });
    })

    $('#aside-search').click(function(){
        var data = {
            "ad.rooms": {
                "operation": "set",
                "data": checks('gl-search-rooms')
            },
            "district.id": {
                "operation": "set",
                "data": select('gl-search-districts')
            },
            "house.address": {
                "operation": "text",
                "data": text('gl-search-address')
            },
            "house.floor": {
                "operation": "range",
                "data": range('gl-search-floors')
            },
            "ad.floor": {
                "operation": "range",
                "data": range('gl-search-floor')
            },
            "house.buildYear": {
                "operation": "range",
                "data": range('gl-search-build_year')
            },
            "complex.id": {
                "operation": "set",
                "data": select('gl-search-complexes')
            },
            "house.minPrice": {
                "operation": "range",
                "data": range('gl-search-min_price')
            },
            "ad.price": {
                "operation": "range",
                "data": range('gl-search-price')
            },
            "ad.squareAll": {
                "operation": "range",
                "data": range('gl-search-square_all')
            }
        };

        var entity = $(this).attr('data-entity');

        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: '/api/search',
            data: {'entity': entity,'data': data}
        }).complete(function(data){
            document.location.href = data.responseJSON.url;
        });

    })

    $('.listing-sort').change(function(){
        document.location.href = $(this).val();
    })

    var text = function(id){
        var element = $('#' + id);
        return element.val();
    }

    var select = function(id){
        var element = $('#' + id);
        if (element.length > 0) {
            return element.val()
        } else {
            return 0;
        }
    }

    var range = function(id){
        var element = $('#' + id);
        if (element.length > 0) {
            return [
                element.find('.value-from').first().html(),
                element.find('.value-to').first().html()
            ];
        } else {
            return [];
        }
    };

    var checks = function(id){
        var data = [];
        var element = $('#' + id);
        if (element.length > 0 ) {
            element.find('input:checkbox').each(function () {
                if ($(this).is(":checked")) {
                    data.push(parseInt($(this).val()));
                }
            });
        }
        return data;
    };
})
/**
 * Created by axxil on 03.11.16.
 */

$(document).ready(function(){
    $('.form-group--range').each(function(index){
        var block = $(this);
        var propertyPriceRange = block.find('.range-area')[0];
        var from = $(block.find('div.value-from').first());
        var to = $(block.find('div.value-to').first());
        var propertyPriceRangeValues = [from,to];

        noUiSlider.create (propertyPriceRange, {
            start: [parseInt(from.attr('data-current')), parseInt(to.attr('data-current'))],
            connect: true,
            range: {
                'min': parseInt(from.attr('data-default')),
                'max': parseInt(to.attr('data-default'))
            },
            step: parseInt(block.attr('data-step'))
        });

        propertyPriceRange.noUiSlider.on('update', function( values, handle ) {
            propertyPriceRangeValues[handle].html(values[handle]);
        });
    })
})